import { useContext } from "react";
import AuthStatus from "../AuthStatus/AuthStatus";
import "./style.scss";
import RegistrationContext from "../../contexts/RegistrationContext";

const NavBar2 = () => {
  const { loading } = useContext(RegistrationContext);

  return <div className="navbar">{!loading && <AuthStatus />}</div>;
};

const NavBar = ({ boxed }) => {
  const { loading, authenticated, isSoldout,screen } = useContext(RegistrationContext);

  return (
    <>
      <div className={`navbar ${boxed && "boxed"}`}>
        {!loading && <AuthStatus />}
      </div>
      {authenticated && (screen !== "Confirmation") && (
        <>
          {isSoldout ? (
            <div
              className={`flex flex-col justify-center bg-red-200 ${
                boxed ? "message_box" : "hidden"
              } `}
            >
              <div
                className=" flex justify-center  rounded-lg flex-col lg:flex-row  text-center  "
                style={{
                  margin: "auto",
                  padding: "10px 2em",
                  color: "#20334d",
                }}
              >
                Due to the unprecedented success of ECAI 2024, and to  ensure delegate comfort and safety.
                The registration application is closed to new registrations.
              </div>
            </div>
          ): (
            <div
              className={`flex flex-col justify-center bg-yellow-200 ${
                boxed ? "message_box" : "hidden"
              } `}
            >
              <div
                className=" flex justify-center  rounded-lg flex-col lg:flex-row  text-center  "
                style={{
                  margin: "auto",
                  padding: "10px 2em",
                  color: "#20334d",
                }}
              >
                Due to the unprecedented success of ECAI 2024, registrations are
                nearing the maximum limit to ensure delegate comfort and safety.
                <br /> With this priority in mind, once this limit is reached,
                the registration application will be closed to new
                registrations.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default NavBar;
