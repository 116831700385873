// src/contexts/RegistrationProvider.js

import React, { useState } from "react";
import RegistrationContext from "./RegistrationContext";

const RegistrationProvider = ({ children }) => {
  const [screen, setScreen] = useState("login");
  const [maxStep, setMaxStep] = useState("login");
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [reload, setReload] = useState(0);
  const [data, setData] = useState({});
  const [cartOpen, setCartOpen] = useState(false);
  const [cart, setCart] = useState({});
  const [cartAdded, setCartAdded] = useState(false);
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [phase, setPhase] = useState("");
  const [inCommunication, setInCommunication] = useState(false);
  const [paperOpen, setPaperOpen] = useState(false);
  const [paperSelected, setPaperSelected] = useState([]);
  const [paperSelectedVerified, setPaperSelectedVerified] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isMainAccess, setIsMainAccess] = useState(false);
  const [optionAccess, setOptionAccess] = useState("");
  const [clickedNext, setClickedNext] = useState(0);
  const [prices, setPrices] = useState({});
  const [next, setNext] = useState(null);
  const [highlightedCart, setHighlightedCart] = useState(false);
  const [extraPages, setExtraPages] = useState({});
  const [upgradableOptions, setUpgradableOptions] = useState([]);
  const [isSoldout, setIsSoldout] = useState([]);
  return (
    <RegistrationContext.Provider
      value={{
        screen,
        setScreen,
        authenticated,
        setAuthenticated,
        loading,
        setLoading,
        error,
        setError,
        status,
        setStatus,
        reload,
        setReload,
        maxStep,
        setMaxStep,
        data,
        setData,
        cartOpen,
        setCartOpen,
        cart,
        setCart,
        cartAdded,
        setCartAdded,
        userInfoOpen,
        setUserInfoOpen,
        email,
        setEmail,
        phase,
        setPhase,
        inCommunication,
        setInCommunication,
        paperOpen,
        setPaperOpen,
        paperSelected,
        setPaperSelected,
        paperSelectedVerified,
        setPaperSelectedVerified,
        isAdmin,
        setIsAdmin,
        isImpersonating,
        setIsImpersonating,
        isStudent,
        setIsStudent,
        isMainAccess,
        setIsMainAccess,
        optionAccess,
        setOptionAccess,
        clickedNext,
        setClickedNext,
        prices,
        setPrices,
        highlightedCart,
        setHighlightedCart,
        next,
        setNext,
        extraPages,
        setExtraPages,
        upgradableOptions,
        setUpgradableOptions,
        isSoldout,
        setIsSoldout
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationProvider;
